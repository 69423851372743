import React, { useState } from 'react'
import Header from '../layout/Header'
import { Col, Container, Row, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import '../../styles/uniform.css'
import InputTextField from '../inputfields/InputTextField'
import InputTextArea from '../inputfields/InputTextArea'
import * as yup from 'yup'
import { useFormik } from 'formik'
import InputButton from '../inputfields/InputButton'
import { useDispatch } from 'react-redux'
import { userVault } from '../redux/actions/EmpAction'
import BackDrop from '../inputfields/BackDrop'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Vault = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { values, errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue } = useFormik({

        initialValues: { name: '', phone: '', email: '', e_id: '', eq_detail: '', mail_address: '' },
        validationSchema: yup.object({
            e_id: yup.string().required('phone-number is required!').matches(/^[0-9]+$/, "must be only digits"),
            name: yup.string().required("Product name is required"),
            eq_detail: yup.string().required("equipment details is required"),
            mail_address: yup.string().required("mailing address is required"),
            email: yup.string().email('Invalid Email address').required("Email is required"),
            phone:yup.string().required('phone-number is required!').matches(/^[\d-]+$/, "must be only digits")
            .min(12, 'must be exactly 10 digits')
            .max(12, 'must be exactly 10 digits'),
        }),
        onSubmit: (values, { resetForm }) => {
            resetForm({ values: '' });
            const formdata = new FormData();
            formdata.append('name', values.name)
            formdata.append('mobile_no', values.phone)
            formdata.append('email', values.email)
            formdata.append('employee_id', values.e_id)
            formdata.append('required_equipment', values.eq_detail)
            formdata.append('mail_address', values.mail_address)
            setLoading(true)
            dispatch(userVault(formdata, setLoading,toast));
        }
    })

    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
    }
    const handleInput = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setFieldValue('phone', formattedPhoneNumber);
    };

    return (
        <>
            {loading ? <BackDrop /> : null}
            <ToastContainer />
            <div className='wrapper'>
            <Container >
                <Row>
                    <h5 className='mb-3'>Please fill out the Order form below for Equipment's</h5>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicName">
                            <InputTextField type={'text'} placeholder={'Enter Name'}
                                id='name'
                                name="name"
                                className="form-control"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.name}
                                error={errors.name}
                                touch={touched.name}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicPhone">
                            <InputTextField type={'text'} placeholder={'Phone No.'}
                                id='phone'
                                name="phone"
                                className="form-control"
                                handleChange={(e) => handleInput(e)}
                                handleBlur={handleBlur}
                                value={values.phone}
                                error={errors.phone}
                                touch={touched.phone}
                            />
                        </Form.Group>
                    </Row>
                    <Row>

                        <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicEmail">
                            <InputTextField type={'email'} placeholder={'Enter Email Address'}
                                id='email'
                                name="email"
                                className="form-control"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.email}
                                error={errors.email}
                                touch={touched.email}
                            />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" xs={'12'} md={'6'} controlId="formBasicId">
                            <InputTextField type={'text'} placeholder={'Enter Employee ID'}
                                id='e_id'
                                name="e_id"
                                className="form-control"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.e_id}
                                error={errors.e_id}
                                touch={touched.e_id}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3" xs={'12'} controlId="exampleForm.ControlTextarea1">
                            <InputTextArea as={'textarea'} rows={3} placeholder={'Please enter required equipment details'}
                                id='eq_detail'
                                name="eq_detail"
                                className="form-control"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.eq_detail}
                                error={errors.eq_detail}
                                touch={touched.eq_detail}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3" xs={'12'} controlId="exampleForm.ControlTextarea1">
                            <InputTextArea as={'textarea'} rows={3} placeholder={'Enter Mailing Address'}
                                id='mail_address'
                                name="mail_address"
                                className="form-control"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.mail_address}
                                error={errors.mail_address}
                                touch={touched.mail_address}
                            />
                        </Form.Group>
                    </Row>
                    <div className='text-start'>
                        <InputButton type="submit" className="button-uniform" name="Submit" />
                    </div>
                </Form>
            </Container>
            </div>
        </>
    )
}

export default Vault