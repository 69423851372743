import axios from "axios"
import { storeLoggedInUser, storeUserRole, storeUserToken } from "../../../services/LocalStorageService"
import { EMP_OF_WEEK } from "../../../services/RouthPath"
import { client } from "../client"

export const USER_LOGIN = "USER_LOGIN"
export const USER_STAFF_DIRECTORY = "USER_STAFF_DIRECTORY"
export const USER_FORMS = "USER_FORMS"
export const USER_EMP_OF_WEEK = "USER_EMP_OF_WEEK"
export const USER_TRAINING_VIDEOS = "USER_TRAINING_VIDEOS"
export const USER_VAULT = "USER_VAULT"
export const USER_UNIFORM = "USER_UNIFORM"
export const UPCOMING_EVENTS = "UPCOMING_EVENTS"
export const USER_MAP_DIRECTION = "USER_MAP_DIRECTION"
export const USER_FAQ = "USER_FAQ"
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_UNIFORM_CAT="USER_UNIFORM_CAT"
export const USER_UNIFORM_SUBCAT="USER_UNIFORM_SUBCAT"
export const USER_VENUE="USER_VENUE"
export const USER_VENUE_LIST="USER_VENUE_LIST"

// -----------------------------------------login-----------------------------------------------------------------
export const userLogin = (obj, setLoading, navigate, setError) => (dispatch) => {
  client.post('/login', obj).then((response) => {
    // console.log(response.data.data, '--------------login-----------------------')
    storeUserToken(response.data.data.token);
    storeUserRole(`${response.data.data.role}`);
    storeLoggedInUser(response.data.data);
    dispatch({
      type: USER_LOGIN,
      payload: response.data.data
    })
    setLoading(false);
    navigate(EMP_OF_WEEK)
  }).catch((error) => {
    setError(error.response.data.data)
    setLoading(false);
  })
}

// -----------------------------------------get logout-----------------------------------------------------------------
export const userLogout = (navigate,setLoading) => (dispatch) => {
  client.get('user/logout').then((response) => {
    dispatch({
      type: USER_LOGOUT,
    })
    // setLoading(false)
    localStorage.clear();
    navigate('/')
    window.location.reload();
  }).catch((error) => {
  })
}

// -----------------------------------------get staff-directory-----------------------------------------------------------------
export const userStaffDirectory = (setStaffDirectory,setLoading) => (dispatch) => {
  client.get('/user/list/staff-directory').then((response) => {
    dispatch({
      type: USER_STAFF_DIRECTORY,
      payload: response.data.data.data,
      path:response.data.data.path
    })
    setStaffDirectory(response.data.data.data)
    setLoading(false)
  }).catch((error) => {
  })
}

// -----------------------------------------get forms-----------------------------------------------------------------
export const userForms = (setLoading) => (dispatch) => {
  client.get('user/list/forms').then((response) => {
    dispatch({
      type: USER_FORMS,
      payload: response.data.data.data,
      path: response.data.data.path
    })
    setLoading(false)
  }).catch((error) => {
  })
}

// -----------------------------------------get employee-of-the-week-----------------------------------------------------------------
export const userEmpOfWeek = (setLoading) => (dispatch) => {
  client.get('user/list/employee-of-the-week').then((response) => {
    dispatch({
      type: USER_EMP_OF_WEEK,
      payload: response.data.data.data,
      path: response.data.data.path
    })
    setLoading(false)
  }).catch((error) => {
  })
}

// ---------------------------------------get training videos-----------------------------------------------------
export const userTrainingVideos = (setLoading) => (dispatch) => {
  client.get('user/list/training-video').then((response) => {
    dispatch({
      type: USER_TRAINING_VIDEOS,
      payload: response.data.data.data,
      path: response.data.data.path,
      lmsObj:response.data.data?.lms_link
    })
    setLoading(false)
  }).catch((error) => {
    // console.log(error,"---------------error")
  })
}

// -----------------------------------------get faq-----------------------------------------------------------------
export const userFAQ = (setLoading) => (dispatch) => {
  client.get('user/list/faq').then((response) => {
    dispatch({
      type: USER_FAQ,
      payload: response.data.data,
    })
    setLoading(false)

  }).catch((error) => {
    // console.log(error,"---------------error")
  })
}

// -----------------------------------------add vault-----------------------------------------------------------------
export const userVault = (obj,setLoading,toast) => (dispatch) => {
  client.post('user/vault/add', obj).then((res) => {
    dispatch({
      type: USER_VAULT,
    })
    toast.success("Added sucessfully!");
    setLoading(false);
  }).catch((e) => {console.log(e);toast.error("This is an error!");})
}

// -----------------------------------------add uniform-----------------------------------------------------------------
export const userUniform = (obj,setLoading,toast) => (dispatch) => {
  client.post('user/uniform/add', obj).then((res) => {
    dispatch({
      type: USER_UNIFORM,
    })
    setLoading(false);
    toast.success("Added sucessfully!");
  }).catch((e) => {console.log(e);toast.error("This is an error!");})
}

// -----------------------------------------get upcoming-events-----------------------------------------------------------------
export const upcomingEvents = (setLoading) => (dispatch) => {
  client.get('user/list/upcoming-events').then((res) => {
    dispatch({
      type: UPCOMING_EVENTS,
      payload: res.data.data.data,
      event_path: res.data.data.path
    })
    setLoading(false)
  }).catch((e) => console.log(e))
}

export const userMapDirection = (setLoading,sortType) => (dispatch) => {
  client.get(`user/list/map-direction`).then((response) => {
    // console.log(response.data.data.path, '-------------map----------------------')
    dispatch({
      type: USER_MAP_DIRECTION,
      payload: response.data.data.data,
      map_pdf_path:response.data.data.path
    })
    setLoading(false)
  }).catch((error) => {
    // console.log(error,"---------------error")
  })
}
export const userUniformCategory = () => (dispatch) => {
  client.get('user/list/uniform-category').then((response) => {
    dispatch({
      type: USER_UNIFORM_CAT,
      payload: response.data.data,
    })

  }).catch((error) => {
  })
}
export const userUniformSubCategory = (id) => (dispatch) => {
  client.get(`/user/list/uniform/${id}`).then((response) => {

    dispatch({
      type: USER_UNIFORM_SUBCAT,
      payload: response.data.data,
    })

  }).catch((error) => {
  })
}
export const userVenue = (setLoading,sortType) => (dispatch) => {
  client.get(`user/list/venue?sort=${sortType}`).then((response) => {
    dispatch({
      type: USER_VENUE,
      payload: response.data.data,
      lastPage:response.data.data.last_page
    })
    setLoading(false)
  }).catch((error) => {
    // console.log(error,"---------------error")
  })
}
export const userVenueList= (id,setLoading) => (dispatch) => {
  client.get(`/user/list/venue/${id}`).then((response) => {

    dispatch({
      type: USER_VENUE_LIST,
      payload: response.data.data.data,
    })
    setLoading(false)

  }).catch((error) => {
    setLoading(false)
  })
}