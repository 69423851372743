import axios from "axios";
// const baseUrl = 'http://proem.cuotainfotech.com/admin/api/'
const baseUrl = 'https://myproem.proem.org/admin/api/'

export const client=   axios.create({
    baseURL:baseUrl,
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':
        {
            toString () {
              return `Bearer ${localStorage.getItem('user-token')}`
            }
        }
}});