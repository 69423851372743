import React, { useState } from 'react'
import Header from '../layout/Header'
import { Col, Container, Row, Form } from 'react-bootstrap'
import '../../styles/uniform.css'
import * as yup from 'yup'
import { useFormik } from 'formik'
import InputTextField from '../inputfields/InputTextField'
import InputTextArea from '../inputfields/InputTextArea'
import InputButton from '../inputfields/InputButton'
import { useDispatch, useSelector } from 'react-redux'
import { userUniform, userUniformCategory } from '../redux/actions/EmpAction'
import BackDrop from '../inputfields/BackDrop'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchSelect from '../inputfields/SearchSelect'
import { useEffect } from 'react'

const Uniform = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { values, errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue } = useFormik({

        initialValues: { name: '', phone: '', email: '', e_id: '', sizes: '', mail_address: '' ,category:'',subCategory:''},
        validationSchema: yup.object({
            e_id: yup.string().required('Employee id is required').matches(/^[0-9]+$/, "Must be only digits"),
            name: yup.string().required("Product name is required"),
            sizes: yup.string().required("Equipment details is required"),
            mail_address: yup.string().required("Mailing address is required"),
            email: yup.string().email('Invalid Email address').required("Email is required"),
            phone: yup.string().required('Phone-number is required').matches(/^[\d-]+$/, "Must be only digits")
                .min(12, 'Must be exactly 10 digits')
                .max(12, 'Must be exactly 10 digits'),
            category: yup.object().required("Category is required"),
            subCategory: yup.object().required("Sub category is required"),
        }),
        onSubmit: (values, { resetForm }) => {

            resetForm({ values: '' });
            const formdata = new FormData();
            formdata.append('name', values.name)
            formdata.append('mobile_no', values.phone)
            formdata.append('email', values.email)
            formdata.append('employee_id', values.e_id)
            formdata.append('detail_size', values.sizes)
            formdata.append('mail_address', values.mail_address)
            formdata.append('uniform_category_id', values.category?.value)
            formdata.append('uniform_sub_category_id', values.subCategory?.value)
            setLoading(true);
            dispatch(userUniform(formdata, setLoading, toast));
        }
    })
    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
    const handleInput = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setFieldValue('phone', formattedPhoneNumber);
    };
    let load=false;
    useEffect(() => {
        if(!load)
        {
            dispatch(userUniformCategory())
        }
        return ()=>{load=true}
    }, [])
    const uniformCat = useSelector((s) => s.EmpReducer.uniformCat)
    const category = uniformCat?.map((b) => {
        return { value: b?.id, label: b?.name }
    })
    return (
        <>
            {loading ? <BackDrop /> : null}
            <ToastContainer />
            <div className='wrapper'>
                <Container>
                    <Row>
                        <h5 className='mb-3'>Please fill out the form below to get your PROEM uniform</h5>
                    </Row>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicName">
                                <InputTextField type={'text'} placeholder={'Enter Name'}
                                    id='name'
                                    name="name"
                                    className="form-control"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.name}
                                    error={errors.name}
                                    touch={touched.name}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicPhone">
                                <InputTextField type={'text'} placeholder={'Phone No.'}
                                    id='phone'
                                    name="phone"
                                    className="form-control"
                                    handleChange={(e) => handleInput(e)}
                                    handleBlur={handleBlur}
                                    value={values.phone}
                                    error={errors.phone}
                                    touch={touched.phone}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicEmail">
                                <InputTextField type={'email'} placeholder={'Enter Email Address'}
                                    id='email'
                                    name="email"
                                    className="form-control"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.email}
                                    error={errors.email}
                                    touch={touched.email}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicId">
                                <InputTextField type={'text'} placeholder={'Enter Employee ID'}
                                    id='e_id'
                                    name="e_id"
                                    className="form-control"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.e_id}
                                    error={errors.e_id}
                                    touch={touched.e_id}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicEmail">
                                <SearchSelect
                                    name="category"
                                    opt={category}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    value={values.category}
                                    error={errors.category}
                                    touch={touched.category}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={'12'} md={'6'} className="mb-3" controlId="formBasicId">
                            <SearchSelect
                                    name="subCategory"
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    value={values.subCategory}
                                    error={errors.subCategory}
                                    touch={touched.subCategory}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs={'12'} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <InputTextArea as={'textarea'} rows={3} placeholder={'Please enter details about sizes'}
                                    id='sizes'
                                    name="sizes"
                                    className="form-control"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.sizes}
                                    error={errors.sizes}
                                    touch={touched.sizes}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={'12'} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <InputTextArea as={'textarea'} rows={3} placeholder={'Enter Mailing Address'}
                                    id='mail_address'
                                    name="mail_address"
                                    className="form-control"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.mail_address}
                                    error={errors.mail_address}
                                    touch={touched.mail_address}
                                />
                            </Form.Group>
                        </Row>
                        <Row>

                        </Row>
                        <div className='text-start'>
                            <InputButton type="submit" className="button-uniform" name="Submit" />
                        </div>
                    </Form>
                </Container>
            </div>
        </>
    )
}

export default Uniform