import { Col, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import '../../styles/login.css'
import { bg_color } from '../../variables/colors';
import InputButton from '../inputfields/InputButton';
import InputLabel from '../inputfields/InputLabel';
import InputTextField from '../inputfields/InputTextField';
import * as yup from 'yup'
import { useFormik } from 'formik';
import { userLogin } from '../redux/actions/EmpAction';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

function Login({ loading, setLoading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Error, setError] = useState();
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      email: "",
      // username: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().email('Invalid Email address').required("Email is required"),
      // username: yup.string().required('Username is required!'),
      password: yup.string().min(6, 'Password must be 6 character length').required('Password is required!'),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      dispatch(userLogin({ email: values.email, password: values.password, role: 2 }, setLoading, navigate, setError));
      resetForm({ values: '' });
    }
  })

  return (
    <>

      {Error && <Alert key="danger" variant="danger" className='mt-2'>
        {Error}
      </Alert>}

      <Form className='form' onSubmit={handleSubmit}>
        <Form.Group className="mb-3 w-75 justify-content-center mx-auto" controlId="formBasicEmail" >
          <InputLabel
            className="text-start w-100 label"
            name="Email Address"
          />
          <InputTextField
            id='email'
            name="email"
            placeholder="Enter email address"
            type="text"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.email}
            error={errors.email}
            touch={touched.email}
          
          />
        </Form.Group>

        <Form.Group className="mb-3 w-75 mx-auto" controlId="formBasicPassword">
          <InputLabel
            className="text-start w-100 label"
            name="Password"
          />
          <InputTextField
            id='password'
            name="password"
            placeholder="Enter Password"
            type="password"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touch={touched.password}
          />
        </Form.Group>

        <div className='text-center w-100'>
          <InputButton type="submit" className='button' name="Login" />
        </div>
      </Form>
    </>
  );
}

export default Login;