import React, { useEffect, useState } from 'react'
import "../../css/Header.css"
import { BsArrowLeft } from "react-icons/bs";
import ImgRibbonCard from './ImgRibbonCard';
import { useLocation, useNavigate } from 'react-router';
import { EMP_OF_WEEK, EVENTS, FAQ, FORMS, MAP_DIRECTION, PRO_EM_TRAINING, STAFF_DIRECTORY, UNIFORM, VAULT } from '../../services/RouthPath';
import InputButton from '../inputfields/InputButton';
import { getLoggedInUser } from '../../services/LocalStorageService';
import { useDispatch } from 'react-redux';
import { userLogout } from '../redux/actions/EmpAction';
import { ReactComponent as ReactLogo } from '../../assets/back.svg';


const Header = () => {
  const location = useLocation();
  const [heading, setHeading] = useState("");
  const navigate = useNavigate();
  const user = getLoggedInUser();

  const dispatch = useDispatch();
  useEffect(() => {
    switch (location.pathname) {
      case PRO_EM_TRAINING:
        setHeading("PRO EM Training")
        break;
      case STAFF_DIRECTORY:
        setHeading("Staff Directory")
        break;
      case EVENTS:
        setHeading("Upcoming Events")
        break;
      case UNIFORM:
        setHeading("PRO EM Uniform")
        break;
      case VAULT:
        setHeading("Vault")
        break;
      case MAP_DIRECTION:
        setHeading("Map & Directions")
        break;
      case FORMS:
        setHeading("Forms")
        break;
      case FAQ:
        setHeading("Frequently Asked Questions")
        break;
    }
  }, [location])

  return (
    <>
      {/* <header> */}
      <div className='header'>
        <div className='container-fluid header-container'>
          <div className='row w-100 p-0 m-0'>
            <div className='col-5 col-lg-2 d-flex flex-column'>
              <div className='logo-main'><img src={require('../../assets/logo.png')} alt='logo' className='w-100'></img></div>
              {location.pathname != EMP_OF_WEEK && <div  className='mt-2 back-aerrow-container logo-main' style={{
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
              }}>
                <InputButton type="button" className='btn-blueviolet ms-2 button-lg' name="Back" icon={
                  <ReactLogo className='back-aerrow-img' />
                } onClick={() => navigate(EMP_OF_WEEK)} />
              </div>}
            </div>
            <div className='col-7 col-lg-10 '>
              <div className='text-end text-md-start d-flex align-items-center  justify-content-end'>
                <span className='welcome-text text-capitalize me-md-1 me-lg-5 mt-sm-0'> Welcome {user?.name}</span>
                <InputButton type="button" className='btn-blueviolet ms-2 button-lg' name="Logout" onClick={() => dispatch(userLogout(navigate))} />
              </div>
              <div className={`text-end`} style={{ display: location.pathname == EMP_OF_WEEK ? "none" : "block" }}>
                {/* <BsArrowLeft className='left-arrow-header' onClick={() => navigate(EMP_OF_WEEK)} style={{ cursor: "pointer" }} /> */}
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* </header> */}

      {/* {
        location.pathname == EMP_OF_WEEK ? <header> <div className='header'>
          <div className='container-fluid ' style={{ padding: "0px 8%" }}>
            <div className='row'>
              <div className='col-6  col-md-3 col-lg-2'>
                <a href='https://proem.org/' target="_blank">
                  <div className='logo-main'><img src={require('../../assets/logo.png')} alt='logo' className='w-100'></img></div>
                </a>
              </div>
              <div className='col-4 d-none d-md-block col-md-6 col-lg-8'> <ImgRibbonCard /></div>

              <div className='col-6 col-md-3 col-lg-2 text-end text-md-start  d-flex align-items-md-start align-items-center  justify-content-end'>
                <span className='welcome-text text-capitalize me-md-3 me-lg-5 mt-md-2 mt-sm-0'> Welcome {user?.name}</span>
                <InputButton type="button" className='btn-blueviolet ms-2 button-lg' name="Logout" onClick={() =>dispatch(userLogout(navigate))} />
              </div>
            </div>
          </div>
        </div></header> : <header><div className='header'> <div className='container-fluid sec-header'>
          <div className='row w-100'>
            <div className='col-6  col-md-4 col-lg-2'>
              <a href='https://proem.org/' target="_blank">
                <div className='logo-main'><img src={require('../../assets/logo.png')} alt='logo' className='w-100'></img></div>
              </a>
            </div>
            <div className='col-4 d-none d-md-block col-md-4 col-lg-8 main-header'>
              <h2 className='header_heading'>{heading}</h2>
            </div>
            <div className='col-6 col-md-4 col-lg-2'>
           
              <div className=' text-end text-md-start d-flex align-items-md-start align-items-center  justify-content-end'>
                <span className='text-capitalize  welcome-text  me-md-3 me-lg-5 mt-md-2 mt-sm-0'> Welcome {user?.name}</span>
                <InputButton type="button" className='btn-blueviolet ms-2 button-lg' name="Logout" onClick={() =>dispatch(userLogout(navigate))}/>
              </div>
              <div className='text-center'><BsArrowLeft className='left-arrow-header mx-2' onClick={() =>navigate(EMP_OF_WEEK)} style={{ cursor: "pointer" }} /></div>
            </div>
          
          </div>
        </div>
        </div>
        </header>
      } */}

    </>
  )
}

export default Header