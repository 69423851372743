import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { AiFillPlusCircle } from "react-icons/ai";
import "../../css/FAQ.css"
import { useDispatch, useSelector } from 'react-redux';
import { userFAQ } from '../redux/actions/EmpAction';
const Faq = () => {

    const array = [
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            name: "Accordion Item #1",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },

    ]

    const dispatch = useDispatch();
    const faq = useSelector((state) => state.EmpReducer.faq);
    const [activeKey,setActiveKey] = useState(0);

    // const showSubList = (num) => {
    //     if (faq?.length > 0) {
    //         var id = document.getElementById(`list${num}`);
    //         faq.map((item) => {
    //             var listId = document.getElementById(`list${item.id}`);
    //             if (item.id == num) return false
    //             else if (listId.classList.contains('d-block')) {
    //                 listId.classList.remove('d-block');
    //                 listId.classList.add('d-none');
    //             }
    //         })
    //         if (id.classList.contains('d-block')) {
    //             id.classList.remove('d-block');
    //             id.classList.add('d-none');
    //         }
    //         else if (!id.classList.contains('d-block')) {
    //             id.classList.remove('d-none');
    //             id.classList.add('d-block');
    //         }
    //     }
    // }

    let pageLoaded = false;
    useEffect(() => {
        if (!pageLoaded) dispatch(userFAQ());
        return () => pageLoaded = true
    }, [pageLoaded])
    return (
        <>
            <div className='wrapper'>
                <Container>
                    {console.log(activeKey,'---activeKey----')}
                    <Accordion  activeKey={activeKey}>
                        <Row className='justify-content-center w-100 m-0'>
                            <Col sm={'5'} className='mx-md-2 mb-2'>
                                <Row>
                                    {faq && faq.slice(0, (faq.length) / 2).map((item, index) => {
                                        return <Col sm={'12'} className="p-0" key={index}>
                                            {console.log('item?.id',item?.id)}
                                            <Accordion.Item eventKey={item?.id} onClick={() => {
                                                if(activeKey == item?.id) setActiveKey('')
                                                else setActiveKey(item?.id)
                                                // showSubList(item.id);
                                                }} className="accordation-main">
                                                <div className='accordation-header-main border-start-0' style={{borderBottom:activeKey == item?.id ? '1px solid #c280ff' : 0}}>
                                                    <div className='w-100 h-100' style={{ borderRight: '3px solid #c280ff', padding: '15px 0px' }}>
                                                        <p className="text-question p-0 ps-2">{item.question}</p>
                                                    </div>

                                                    {/* <p className='text-question p-0'>{item.question}</p> */}
                                                    <div className='plus-circle-main'><AiFillPlusCircle className='plus-circle' /></div>
                                                </div>
                                                <Accordion.Body className='d-none border-0' id={`list${item.id}`}>
                                                    {item.answer}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Col> 
                                    })}
                                </Row>
                            </Col>
                            <Col sm={'5'}>
                                <Row>
                                    {faq && faq.slice((faq.length) / 2, faq.length).map((item, index) => {
                                        return <Col sm={'12'} className="p-0" key={index}>
                                            <Accordion.Item eventKey={item?.id} onClick={() => {
                                               if(activeKey == item?.id) setActiveKey('')
                                               else setActiveKey(item?.id)
                                                // showSubList(item.id)
                                                }} className="accordation-main">
                                                <div className='accordation-header-main border-start-0' style={{borderBottom:activeKey == item?.id ? '1px solid #c280ff' : 0}}>
                                                    <div className='w-100 h-100' style={{ borderRight: '3px solid #c280ff', padding: '15px 0px' }}>
                                                        <p className="text-question p-0 ps-2">{item.question}</p>
                                                    </div>

                                                    {/* <p className='text-question p-0'>{item.question}</p> */}
                                                    <div className='plus-circle-main'><AiFillPlusCircle className='plus-circle' /></div>
                                                </div>
                                                <Accordion.Body className='d-none border-0' id={`list${item.id}`}>
                                                    {item.answer}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Col>
                                    })}
                                </Row>
                            </Col>


                        </Row>
                    </Accordion>
                </Container>
            </div>
        </>
    )
}

export default Faq