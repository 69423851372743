import React from 'react'
import { IoIosSchool } from "react-icons/io";
import "../../css/Footer.css"
import moment from 'moment';
const Footer = () => {
    return (
        <>
            {/* <footer>
                <div className='container-fluid '>
                    <div className='row position-fixed w-100 fixed-bottom bg-white'>
                        <div className='col-12 justify-content-center'>
                            <span className='copy-right-text text-start'>Copyright © PRO EM {moment(new Date()).format('YYYY')}</span>
                        </div>
                    </div>
                </div>
            </footer> */}
            <footer>
                <div className='container-fluid '>
                    <div className='row'>
                        <div className='col-12 justify-content-center'>
                            <span className='copy-right-text text-start'>Copyright © PRO EM {moment(new Date()).format('YYYY')}</span>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer