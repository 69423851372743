import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './component/layout/Header';
import Home from './component/pages/Home';
import UserForm from './component/authPages/UserForm';
import { Routes, Route } from "react-router-dom";
import StaffDirectory from './component/pages/StaffDirectory';
import ProEmTraining from './component/pages/ProEmTraining';
import Events from './component/pages/Events';
import { EMP_OF_WEEK, EVENTS, FAQ, FORMS, LOGIN, MAP_DIRECTION, PRO_EM_TRAINING, STAFF_DIRECTORY, UNIFORM, VAULT } from './services/RouthPath';
import Protected from './component/protected/Protected';
import UserAuthProvider from './component/context/UserAuthProvider';
function App() {
  return (
    <>
      <UserAuthProvider>
        <Routes>
          <Route path={EMP_OF_WEEK} element={<><Protected Component={Home} path={EMP_OF_WEEK}></Protected></>} />
          <Route path="/" element={<><Protected Component={UserForm} path={"/"}></Protected></>} />
          <Route path={STAFF_DIRECTORY} element={<><Protected Component={Home} path={STAFF_DIRECTORY}></Protected></>} />
          <Route path={PRO_EM_TRAINING} element={<><Protected Component={Home} path={PRO_EM_TRAINING}></Protected></>} />
          <Route path={EVENTS} element={<><Protected Component={Home} path={EVENTS}></Protected></>} />
          <Route path={UNIFORM} element={<><Protected Component={Home} path={UNIFORM}></Protected></>} />
          <Route path={VAULT} element={<><Protected Component={Home} path={VAULT}></Protected></>} />
          <Route path={MAP_DIRECTION} element={<><Protected Component={Home} path={MAP_DIRECTION}></Protected></>} />
          <Route path={FORMS} element={<><Protected Component={Home} path={FORMS}></Protected></>} />
          <Route path={FAQ} element={<><Protected Component={Home} path={FAQ}></Protected></>} />
        </Routes>
      </UserAuthProvider>

    </>
  );
}

export default App;
