import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router';
import { EVENTS, FAQ, FORMS, MAP_DIRECTION, PRO_EM_TRAINING, STAFF_DIRECTORY, TRAINING, UNIFORM, VAULT } from '../../services/RouthPath';
import ImgRibbonCard from '../layout/ImgRibbonCard';
import '../../css/Content.css'

const Content = () => {
    const navigate = useNavigate();

    const array = [
        {
            image: <img src={require('../../assets/1.jpg')} alt='map' className="card-img" ></img>,
            name: "Maps & Directions",
            path: MAP_DIRECTION
        },
        {
            image: <img src={require('../../assets/2.jpg')} alt='event' className="card-img" ></img>,
            name: "Upcoming Events",
            path: EVENTS
        },
        {
            image: <img src={require('../../assets/3.jpg')} alt='staff' className="card-img" ></img>,
            name: "Staff Directory ",
            path: STAFF_DIRECTORY
        },
        {
            image: <img src={require('../../assets/4.jpg')} alt='security' className="card-img" ></img>,
            name: "Request PRO EM Uniform",
            path: UNIFORM
        },
        // {
        //     image: <img src={require('../../assets/5.jpg')} alt='valut' className="card-img" ></img>,
        //     name: "Request",
        //     path: VAULT

        // },
        {
            image: <img src={require('../../assets/6.jpg')} alt='form' className="card-img" ></img>,
            name: "Download Forms",
            path: FORMS
        },
        {
            image: <img src={require('../../assets/7.jpg')} alt='training' className="card-img" ></img>,
            name: "Training",
            path: PRO_EM_TRAINING
        },
        {
            image: <img src={require('../../assets/8.jpg')} alt='faq' className="card-img" ></img>,
            name: "FAQ",
            path: FAQ
        },

    ]
    const handleClick = (path) => {
        navigate(path);
    }

    return (
        <>
        
            <section className='content-wrapper'>
                <div className='container'>
                    {/* <div className='row'>
                        <div className='col-12 '> <ImgRibbonCard /></div>
                    </div> */}
                    <div className='row'>
                        {
                            array && array.map((item, index) => {
                                return <div className='col-sm-6 mt-0 col-md-4 gx-2 mb-4 col-lg-3 content-card card-radius'  key={index}><Card className='inner-card card-radius' onClick={() => { handleClick(item.path) }}>
                                    {/* <Card.Img variant="top" /> */}
                                    {/* <img  src={require('../assets/map.jpg')} alt='map' style={{height:"200px"}} ></img> */}
                                    {item.image}
                                    <Card.Body className='card-btn'>
                                        <Card.Title className='content-card-title fs-4 mb-0'>{item.name}</Card.Title>
                                    </Card.Body>
                                </Card>
                                </div>
                            })
                        }
                    </div>
                    {/* <div className='main-header'>
                        <button className='btn tell-btn my-5'>Tell Us How We Are Doing</button>
                    </div> */}
                </div>
            </section>


        </>
    )
}

export default Content