import React, { useEffect } from 'react'
import "../../css/ProEmTraining.css"
import { useDispatch, useSelector } from 'react-redux';
import { userTrainingVideos } from '../redux/actions/EmpAction';
const ProEmTraining = () => {
  const dispatch = useDispatch()
  let load = false;
  useEffect(() => {
    if (!load) {
      dispatch(userTrainingVideos())
    }
    return () => { load = true }
  }, [dispatch])
  const trainingVideos = useSelector((s) => s.EmpReducer.trainingVideos)
  const path = useSelector((s) => s.EmpReducer.trainingVideosPath)

  return (
    <>
      <div className='wrapper'>
        <div className='container mb-4' >
          <div className='row'>
            {
              trainingVideos && trainingVideos.map((item, index) => {
                if (item?.description === '1') {
                  return <div className='col-lg-4 col-md-6 gy-5' key={index} >
                    <div className='videos-border p-1 ' style={{ objectFit: "cover" }}>
                      <iframe className='w-100 h-100'  src={item?.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                      <h4 className='text-center title-video mt-3'>{item.title}</h4>
                    </div>
                  </div>
                }
                else if (item?.description === '2') {
                  return <div className='col-lg-4 col-md-6 gy-5' key={index} >
                    <video controls className='videos-border p-1 ' style={{ objectFit: "cover" }}>
                      <source src={`${path}/${item?.video}`} type="video/mp4" />
                    </video>
                    <h4 className='text-center title-video mt-2'>{item.title}</h4>
                  </div>
                }
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ProEmTraining