import React from 'react'
import { Form } from 'react-bootstrap'

export default function InputTextField(  { name, placeholder, type, error, handleBlur, handleChange, touch, value}) {

  return (
    <>
      <Form.Control type={type} placeholder={placeholder} name={name} value={value} onChange={handleChange} onBlur={handleBlur}/>
      {error && touch ? (<span className='text-danger'>{error}</span>) : null}
    </>
  )
}


