import React from 'react'
import { Form } from 'react-bootstrap'
const InputLabel = ({className,name}) => {
    return (
        <div>
            <Form.Label className={className}>{name}</Form.Label>
        </div>
    )
}

export default InputLabel