import React from 'react'
import { Form } from 'react-bootstrap'

export default function InputTextArea({as,rows,placeholder,name,value,handleBlur,handleChange,error,touch}) {
  return (
    <>
      <Form.Control as={as} rows={rows} placeholder={placeholder} className='textarea' name={name} value={value} onChange={handleChange} onBlur={handleBlur}/>
      {error && touch ? (<span className='text-danger'>{error}</span>) : null}
    </>
  )
}


