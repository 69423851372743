import React, { useEffect } from 'react'
import "../../css/StaffDirectory.css"
import Table from 'react-bootstrap/Table';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { HiExternalLink } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux';
import { userForms } from '../redux/actions/EmpAction';
const Forms = () => {
    const dispatch = useDispatch()
    let load = false;
    useEffect(() => {
        if (!load) {
            dispatch(userForms())
        }
        return () => { load = true }
    }, [dispatch])
    const forms = useSelector((s) => s.EmpReducer.forms)
    const path = useSelector((s) => s.EmpReducer.formsPath)
    console.log(forms)
    console.log(path)
    return (
        <>
            <div className='wrapper'>
                <div className='container ' >
                    <div className='row'>
                        <div className='col'>
                            <Table className='staff-table'>
                                <thead className='staff-table-header'>
                                    <tr className='staff-table'>
                                        {/* <th className='staff-table text-start'>Sr.#</th> */}
                                        <th className='staff-table text-start ps-5'>Forms</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        forms && forms?.map((item, index) => {
                                            return <tr className='staff-table staff-table-hover ' key={index}>
                                                {/* <td className='staff-table w-10 text-start text-black fw-bold' style={{fontSize:'15px'}}>{index + 1}</td> */}
                                                <td className='staff-table '>
                                                    <div className='form-text'>
                                                        <p className='m-0 fw-bold text-black ' style={{ fontSize: '15px' }}>{item.name}</p>
                                                        <div className='download-icon-main'>
                                                            {
                                                                item?.type == 'url' ?
                                                                    <HiExternalLink onClick={() => window.open(item?.file, '_blank', 'noopener,noreferrer')}  className='download-icon border-0'/>
                                                                    :

                                                                    <a href={`${path}/${item.file}`} download={item.name} target='_blank'>
                                                                        <BsFillArrowDownCircleFill className='download-icon' />
                                                                    </a>
                                                            }

                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        })
                                    }


                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forms