import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const LoginStyleComp = () => {
    return (
        <Container fluid>
            <Row>
                <Col className='text-white col_style d-flex justify-content-center align-items-center flex-column'>
                    <h1 className='text-capitalize login-right-heading'>welcome to <span className='text-uppercase'>myproem</span></h1>
                    <p className='text-capitalize text-center'>national events gallery | <span className='text-uppercase'>pro em </span>  national event rentals</p>
                    <div className="video-container">
                        <iframe className='video login-video' src="https://www.youtube.com/embed/SZ9nQ9-xGII" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginStyleComp