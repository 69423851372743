import React, { useEffect, useState } from 'react'
import "../../css/Map_Direction.css"
import { IoLocation } from "react-icons/io5";
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { userMapDirection, userVenue, userVenueList } from '../redux/actions/EmpAction';
import { ImArrowRight } from "react-icons/im";
import { TbFileDownload } from 'react-icons/tb'
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useContext } from 'react';
import { UserValAuthContext } from '../context/UserAuthProvider';
import BackDrop from '../inputfields/BackDrop';
import { Accordion } from 'react-bootstrap';
import { thick_border_color } from '../../variables/colors';
import { BiSortAlt2 } from 'react-icons/bi';

const center = {
    lat: -3.745,
    lng: -38.523
};

const Map_Direction = () => {
    const val = useContext(UserValAuthContext);

    const mapDirection = useSelector((s) => s.EmpReducer.mapDirection)
    const path = useSelector((s) => s.EmpReducer.map_pdf_path)

    const [currentPage, setCurrentPage] = useState(1)
    const [eventKey, setEventKey] = useState(0);
    const dispatch = useDispatch()
    let load = false;
    useEffect(() => {
        val.setLoading(true);
        if (!load) {
            dispatch(userMapDirection(val.setLoading))
            dispatch(userVenue(val.setLoading, 'ASC'))
        }
        return () => { load = true }
    }, [dispatch])


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
    })
    const venue = useSelector((s) => s.EmpReducer.venue)

    useEffect(() => {
        if (venue) handleVenue(venue[0]?.id, 0)
    }, [venue])

    const [venueClick, setVenueClick] = useState(false);
    const [venueId, setVenueId] = useState();
    const [sortType,setSortType] = useState(true);

    const handleVenue = (id, index) => {
        val.setLoading(true);
        dispatch(userVenueList(id, val?.setLoading))
        if (venueId == id) {
            setVenueClick(!venueClick);
        }
        else {
            setVenueClick(true)
        }
        setVenueId(id)
        setEventKey(index)
    }
    const venueList = useSelector((s) => s.EmpReducer.venueList)
    const venueLastPage = useSelector((s) => s.EmpReducer.venueLastPage)
    const array_id = mapDirection?.slice(0, 1).find((item, index) => {
        return item
    })
    const venue_id = venueList?.slice(0, 1).find((item, index) => {
        return item
    })
    const [data, setData] = useState({ selected: array_id?.id, lat: array_id?.latitude, lng: array_id?.longitudes, file: '', isLocation: '', title: '', address: '', file: '' })

    useEffect(() => {
        setData({ ...data, selected: array_id?.id, lat: array_id?.latitude, lng: array_id?.longitudes, address: array_id?.address, title: array_id?.location, isLocation: array_id?.is_location, file: array_id?.location_pdf })
    }, [mapDirection])

    useEffect(() => {
        setData({ ...data, selected: venue_id?.id, lat: venue_id?.latitude, lng: venue_id?.longitudes, address: venue_id?.address, title: venue_id?.location, isLocation: venue_id?.is_location, file: venue_id?.location_pdf })
    }, [venueList])

    const handleClick = (item) => {
        setData({ ...data, selected: item?.id, lat: item?.latitude, lng: item?.longitudes, address: item.address, title: item?.location, isLocation: item.is_location, file: item?.location_pdf })
    }

    const handleSort = () => {
        val.setLoading(true);
        setSortType(!sortType)
        dispatch(userVenue(val.setLoading,sortType ? 'DESC' : 'ASC'))
    }

    return (
        <>
            {
                val.loading ? <BackDrop /> : null
            }
            <div className='wrapper'>
                <div className='container' >
                    <div className='row flex-column-reverse flex-sm-row flex-md-row flex-lg-row flex-xl-row p-3 w-100 m-0' style={{ border: `3px solid ${thick_border_color}` }}>
                        <div className='col-12 col-md-6 col-lg-5 col-lg-4 p-0 px-md-2'>
                            <button className='btn btn-blueviolet my-2'>
                                <BiSortAlt2 className='fs-5 text-white text-center' style={{ cursor: 'pointer' }} onClick={() => handleSort('position')} />
                            </button>
                            <Accordion defaultActiveKey={eventKey}>
                                {venue?.map((i, index) => {
                                    return <Accordion.Item eventKey={index}>
                                        <Accordion.Header key={index} className='text-capitalize venue-h5' style={{ cursor: "pointer", color: venueClick && venueId == i.id ? "#7030d6" : "black" }} onClick={() => { handleVenue(i?.id, index) }}><p className='mb-0'>{i?.name}</p></Accordion.Header>
                                        <Accordion.Body>
                                            {venueList?.length > 0 ? venueList.map((item, index) => {
                                                return <div className='location-main-div w-100' onClick={() => handleClick(item)}>
                                                    <div className='location-icon-bg'><h5><IoLocation className='location-icon' /></h5></div>
                                                    <div className='d-flex justify-content-between align-items-center w-100' >
                                                        <div className='d-inline-flex flex-column mt-1'>
                                                            <p className={`location-title ${data.selected == item?.id ? "active-text" : ""} mb-0`}>{item?.location}</p>
                                                            <p className={`location-text`}>{item.address}</p>
                                                        </div>

                                                        {data.selected == item?.id ? <div><ImArrowRight className='arrow-icon' /></div> : null}
                                                    </div>
                                                </div>
                                            }) : <div className='text-center text-dark'>No data found</div>}
                                        </Accordion.Body>
                                    </Accordion.Item>

                                })}
                            </Accordion>
                        </div>
                        <div className=' col-12 col-md-6 col-lg-7 p-0 px-md-2 mb-3 mb-md-0'>
                            {
                                isLoaded && data.isLocation == 1 ?
                                    <div className='main-google-map w-100 h-75'>
                                        <GoogleMap
                                            mapContainerStyle={{ height: "100%", width: "100%" }}
                                            center={{ lat: Number(data.lat || center.lat), lng: Number(data.lng || center.lng) }}
                                            zoom={18}


                                        // onLoad={onLoad}
                                        // onUnmount={onUnmount}
                                        >
                                            <MarkerF position={{ lat: Number(data.lat || center.lat), lng: Number(data.lng || center.lng) }} />
                                        </GoogleMap> </div> : <>
                                        <div className='d-flex justify-content-end'>
                                            <a href={`${path}/${data.file}`} download={data.file} target='_blank' style={{ textDecoration: "none" }}>
                                                <button className='btn btn-blueviolet'><TbFileDownload className='text-white fs-2' /></button>
                                            </a>
                                        </div>
                                        <iframe width="100%" height="70%" className='mt-2' src={`${path}/${data.file}`} title={data.location_pdf}></iframe>
                                    </>

                            }
                            <h4 className={`mt-2`}>{data.title}</h4>
                            <h5 className={`location-address mt-2 ms-1`}>{data.address}</h5>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Map_Direction