import React, { useContext, useEffect, useState } from 'react'
import "../../css/ImgRibbonCard.css"
import { useDispatch, useSelector } from 'react-redux';
import { userEmpOfWeek } from '../redux/actions/EmpAction';
import BackDrop from '../inputfields/BackDrop';
import { UserValAuthContext } from '../context/UserAuthProvider';
import ReactReadMoreReadLess from "react-read-more-read-less";
// import $ from "jquery";


const ImgRibbonCard = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const val = useContext(UserValAuthContext);
    let load = false;
    useEffect(() => {
        // if (!load) {
        val.setLoading(true)
        dispatch(userEmpOfWeek(val.setLoading))
        // }
        // return () => { load = true }
    }, [dispatch])
    const empWeek = useSelector((s) => s.EmpReducer.empWeek)
    const empWeekPath = useSelector((s) => s.EmpReducer.empWeekPath)

    // let cleanHtml = '<div><strong><p>hello mansi ! </p></div>'
    // let text = cleanHtml.replace(/<[^>]+>/g, '');
    // let text = cleanHtml.replace(/<\/?[^>]+(>|$)/g, "");
    // let text =  $(cleanHtml).text()
   
    return (
        <>
            {
                val.loading ? <BackDrop /> : null
            }
            {/* <BackDrop /> */}
            {empWeek && !val.loading ? <section className='mt-5 mt-md-0 pt-3 pt-md-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='ribbon-container'>
                                <img src={require('../../assets/leaf.png')} alt='user' className='leaf-image'></img>
                                <img src={`${empWeekPath}/${empWeek?.photo}`} alt='user' className='employee-image'></img>
                            </div>
                            <h1 className="ribbon">
                                <div className="ribbon-content text-capitalize emp-name fs-4">{`${empWeek?.name}`}</div>
                                <div className="ribbon-content text-capitalize emp-name">{`Employee of the Week`}</div>
                            </h1>
                            <div className='my-4 position-relative z-3'>
                                <ReactReadMoreReadLess
                                    charLimit={100}
                                    readMoreText={"Read more"}
                                    readLessText={"Read less"}
                                    readMoreClassName="read-more-less"
                                    readLessClassName="read-more-less"
                                >
                                    {/* {empWeek?.employee_story ? $(empWeek?.employee_story)[0]?.children[0]?.innerText : "I'm sorry, but I bet you can't is not a topic or prompt for me to rewrite. Can you please provide a different topic or prompt for me to rewriteI'm sorry, but I bet you can't is not a topic or prompt for me to rewrite. Can you please provide a different topic or prompt for me to rewriteI'm sorry, but I bet you can't is not a topic or prompt for me to rewrite. Can you please provide a different topic or prompt for me to rewrite"}                               */}

                                    {empWeek?.employee_story ? empWeek?.employee_story?.replace(/<\/?[^>]+(>|$)/g, "") : "I'm sorry, but I bet you can't is not a topic or prompt for me to rewrite. Can you please provide a different topic or prompt for me to rewriteI'm sorry, but I bet you can't is not a topic or prompt for me to rewrite. Can you please provide a different topic or prompt for me to rewriteI'm sorry, but I bet you can't is not a topic or prompt for me to rewrite. Can you please provide a different topic or prompt for me to rewrite"}
                                </ReactReadMoreReadLess>
                            </div>
                            {/* <div className='mt-3'></div>                              */}
                        </div>
                    </div>
                </div>
            </section> : null}




        </>
    )
}

export default ImgRibbonCard