import React from 'react'
import { Button, Card, Modal } from 'react-bootstrap'

const EventInfo = ({ show, setModalShow, onHide, event, path }) => {
    return (
        <div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
                show={show}
                backdrop={true}
                animation={true}
                restoreFocus={true}
                dialogClassName='custom-modal'
            >
                <Modal.Header closeButton onClick={onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <p style={{fontSize:'18px'}} className='m-0 text-start w-100'>
                            {event?.name}
                        </p>
                        <p className='m-0 text-start w-100' style={{fontSize:'15px'}}>
                            Start date : {event?.start_date_formate} 
                            <br/>
                            End date : {event?.end_date_formate}    
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card key={'index'} className='event_card_info h-100'>
                        <Card.Img variant="top" src={`${path}/${event.image}`} style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
                        <Card.Body className='event_card_text text-white' >
                            <div className='w-100 d-block m-auto'>
                                <p className='m-0 text-left px-1' style={{ fontSize: '15px' }}>
                                    {event?.description?.split('\r\n')[0]}
                                    <br />
                                    {event?.description?.split('\r\n').at(-1)}
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EventInfo
