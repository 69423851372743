import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Login from './Login';
import '../../styles/login.css'
import LoginStyleComp from './LoginStyleComp';
import BackDrop from '../inputfields/BackDrop';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const UserForm = () => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {
        loading ? <BackDrop /> : null
      }
      <Container fluid>
        <Row className='height align-items-center'>
          <Col md={'6'} className='px-lg-5 p-0 p-md-2 transform'>
            <div className='px-5'>
              <img src={require('../../assets/logo.png')} alt="logo" className='image' />
            </div>
            <Login loading={loading} setLoading={setLoading} />
          </Col>
          <Col md={'6'} className='p-0'>
            <LoginStyleComp />
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default UserForm