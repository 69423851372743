import React from 'react'
import Button from 'react-bootstrap/Button';
const InputButton = ({ type, className, name, onClick ,icon}) => {
    return (

        <Button type={type} className={className} onClick={onClick}>{icon ? icon : ''}{name}</Button>

    )
}

export default InputButton