import { USER_EMP_OF_WEEK, USER_FORMS, USER_LOGIN, USER_STAFF_DIRECTORY, USER_TRAINING_VIDEOS ,UPCOMING_EVENTS, USER_UNIFORM, USER_VAULT, USER_MAP_DIRECTION, USER_FAQ, USER_LOGOUT, USER_UNIFORM_CAT, USER_UNIFORM_SUBCAT, USER_VENUE, USER_VENUE_LIST} from "../actions/EmpAction";

const initialState={
    staff:[],
    forms:[],
    formsPath:{},
    empWeek:{},
    empWeekPath:{},
    trainingVideos:[],
    trainingVideosPath:{},
    upcoming_events:[],
    event_path:{},
    mapDirection:[],
    map_pdf_path:{},
    faq:[],
    uniformCat:[],
    uniformSubCat:[],
    venue:[],
    venueList:[],
    staffPath:{},
    venueLastPage:0,
    lmsLinkObj:{}
}
const EmpReducer = function(state=initialState,action){
    switch (action.type) {
        case USER_LOGIN: {
            return {
                ...state,
            }
        }
        case USER_STAFF_DIRECTORY: {
            return {
                ...state,
                staff:action.payload,
                staffPath:action.path
               
            }
        }
        case USER_FORMS: {
            return {
                ...state,
                forms:action.payload,
                formsPath:action.path
               
            }
        }
        case USER_EMP_OF_WEEK: {
            return {
                ...state,
                empWeek:action.payload,
                empWeekPath:action.path
               
            }
        }
        case USER_TRAINING_VIDEOS: {
            return {
                ...state,
                trainingVideos:[...action.payload],
                trainingVideosPath:action.path,
                lmsLinkObj:action.lmsObj
            }
        }
        case USER_VAULT: {
            return {
                ...state,
            }
        }
        case USER_UNIFORM: {
            return {
                ...state,
            }
        }
        case UPCOMING_EVENTS: {
            return {
                ...state,
                upcoming_events:[...action.payload],
                event_path:action.event_path
            }
        }
        case USER_MAP_DIRECTION: {
            return {
                ...state,
                mapDirection:[...action.payload],
                map_pdf_path:action.map_pdf_path
            }
        }
        case USER_FAQ: {
            return {
                ...state,
                faq:[...action.payload],
            }
        }
        case USER_LOGOUT: {
            return {
                ...state,
            }
        }
        case USER_UNIFORM_CAT: {
            return {
                ...state,
                uniformCat:action.payload,
            }
        }
        case USER_UNIFORM_SUBCAT: {
            return {
                ...state,
                uniformSubCat:action.payload,
            }
        }
        case USER_VENUE: {
            return {
                ...state,
                venue:[...action.payload],
                venueLastPage:action.lastPage
            }
        }
        case USER_VENUE_LIST: {
            return {
                ...state,
                venueList:[...action.payload],
            }
        }
        default:
            return state
    }
}

export default EmpReducer