import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { getLoggedInUser, getUserRole, getUserToken } from "../../services/LocalStorageService"
import { EMP_OF_WEEK, EVENTS, FAQ, UNIFORM, FORMS, VAULT, MAP_DIRECTION, STAFF_DIRECTORY, PRO_EM_TRAINING } from '../../services/RouthPath';
const Protected = ({ Component, path }) => {

    let token = getUserToken();
    let user = getLoggedInUser();
    let role = getUserRole();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case "/":
                !role || !user ? navigate("/") : navigate(EMP_OF_WEEK)
                break;
            case EMP_OF_WEEK:
                (role == 2 || role == 3) && token ? navigate(EMP_OF_WEEK) : navigate("/")
                break;
            case EVENTS:
                (role == 2 || role == 3) && token ? navigate(EVENTS) : navigate("/")
                break;
            case UNIFORM:
                (role == 2 || role == 3) && token ? navigate(UNIFORM) : navigate("/")
                break;
            case FAQ:
                (role == 2 || role == 3) && token ? navigate(FAQ) : navigate("/")
                break;
            case FORMS:
                (role == 2 || role == 3) && token ? navigate(FORMS) : navigate("/")
                break;
            case VAULT:
                (role == 2 || role == 3) && token ? navigate(VAULT) : navigate("/")
                break;
            case MAP_DIRECTION:
                (role == 2 || role == 3) && token ? navigate(MAP_DIRECTION) : navigate("/")
                break;
            case STAFF_DIRECTORY:
                (role == 2 || role == 3) && token ? navigate(STAFF_DIRECTORY) : navigate("/")
                break;
            case PRO_EM_TRAINING:
                (role == 2 || role==3) && token ? navigate(PRO_EM_TRAINING) : navigate("/")
                break;
            default:
                break;
        }
        // if (admin_token && (location.pathname == USER_LOGIN || USER_SIGNUP || USER_REGISTER)) {
        //     swal({
        //         icon: "info",
        //         text: "currently you're logged in admin-panel, first logout then login in user-panel!",
        //         // confirmButtonColor: '#0044bb',  
        //         // closeOnConfirm: false,
        //         closeOnClickOutside: false,
        //     }).then(function () {
        //         navigate(LOGIN)
        //     });
        //     if (!token) {
        //         navigate("/")
        //     }
        // }
    }, [])

    return (
        <>
            <Component />
        </>
    )
}

export default Protected