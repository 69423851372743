import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import '../../styles/upcomingEvents.css';
import { RxCalendar } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { upcomingEvents } from '../redux/actions/EmpAction';
import BackDrop from '../inputfields/BackDrop';
import { UserValAuthContext } from '../context/UserAuthProvider';
import EventInfo from '../Modals/EventInfo';

function Events() {
    const val = useContext(UserValAuthContext);
    const dispatch = useDispatch();

    const [modalShow, setModalShow] = useState(false);
    const [event,setEvent] = useState('')


    let pageLoaded = false 
    useEffect(() => {
        if (!pageLoaded) {
            val.setLoading(true)
            dispatch(upcomingEvents(val.setLoading))
        }
        return () => pageLoaded = true
    }, [dispatch])
    const list = useSelector((state) => state.EmpReducer.upcoming_events)
    const path = useSelector((state) => state.EmpReducer.event_path)

    return (
        <>
            {/* {
                val.loading ? <BackDrop /> : null
            } */}
            {modalShow && <EventInfo show={modalShow} onHide={() => {setModalShow(false);setEvent('');}} setModalShow={setModalShow} event={event} path={path}/>}
            <div className='wrapper'>
                <Container className='mb-2' >
                    <Row>
                        {
                            list && list.map((item, index) =>
                                <Col md={'4'} sm={'6'} xs={'12'} className='g-3' key={index}>
                                    <Card key={'index'} className='event_card' onClick={()=>{setModalShow(true);setEvent(item)}}>
                                        <Card.Img variant="top" src={`${path}/${item.image}`} className='event_image' />
                                        <Card.Body className='event_card_text text-white' >
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                                <p className='m-0'>
                                                    <RxCalendar style={{ fontSize: '18px', height: '18px', verticalAlign: 'middle', marginRight: '5px' }} />
                                                </p>
                                                <p className='m-0'>
                                                    {item.name}
                                                </p>
                                            </div>
                                            <div className='w-100'>
                                                <p className='m-0 text-start w-100 ms-3 ps-1'>
                                                    {item?.start_date_formate}
                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>)
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Events;