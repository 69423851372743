import React, { useContext, useState } from 'react'
import Select from 'react-select';
import '../../css/SearchSelect.css'
import { useDispatch, useSelector } from 'react-redux';
import { userUniformSubCategory } from '../redux/actions/EmpAction';
import { UserValAuthContext } from '../context/UserAuthProvider';
const SearchSelect = ({name,opt,setFieldValue,handleBlur,value,error,touch}) => {
   
    const val = useContext(UserValAuthContext);
    const dispatch=useDispatch();
    const handleSelect=(data)=>{
        if(name == 'category')
        {
            val?.setDisable(false);
            setFieldValue('subCategory','')
            dispatch(userUniformSubCategory(data?.value))
        }
        setFieldValue(`${name}`,data)
    }
    const uniformSubCat = useSelector((s) => s.EmpReducer.uniformSubCat)
    const subCategory = uniformSubCat?.map((b) => {
        return { value: b?.id, label: b?.name }
    }) 
    return (
        <>
        <Select
        name={name}
        onChange={handleSelect}
        onBlur={handleBlur}
        options={name == 'subCategory' ? subCategory : opt}
        value={value}
        isDisabled={name == 'subCategory' ? val?.disable : false}
        />
         {error && touch ? (<span className='text-danger'>{error}</span>) : null}
        </>
    )
}

export default SearchSelect