import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "../../css/BackDrop.css"
const BackDrop = () => {
    return (
        <>
            <div className='back-drop'/>
            <Spinner animation="border" className='loader-center' />
           
        </>
    )
}

export default BackDrop