import React, { useEffect, useState } from 'react'
import { BsArrowLeft } from "react-icons/bs";
import Table from 'react-bootstrap/Table';
import "../../css/StaffDirectory.css"
import Header from '../layout/Header';
import { useDispatch, useSelector } from 'react-redux';
import { USER_STAFF_DIRECTORY, userStaffDirectory } from '../redux/actions/EmpAction';
import { Link } from 'react-router-dom';
import { BiSortAlt2 } from 'react-icons/bi';

const StaffDirectory = () => {
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState(true);
    const [staffDirectory, setStaffDirectory] = useState([])
    let load = false;
    useEffect(() => {
        if (!load) {
            dispatch(userStaffDirectory(setStaffDirectory))
        }
        return () => { load = true }
    }, [dispatch])
    
    const staff = useSelector((s) => s.EmpReducer.staff)
    const staff_path = useSelector((s) => s.EmpReducer.staffPath)

    const handleSort = (type) => {
        setSortType(!sortType);
        let arr = staffDirectory;
        if (type == 'name') {

            arr = sortType ? staff.sort((a, b) => a.fname.localeCompare(b.fname)) : staff.sort((a, b) => b.fname.localeCompare(a.fname))
        }
        else if (type == 'position') {
            arr = sortType ? staff.sort((a, b) => a.position.localeCompare(b.position)) : staff.sort((a, b) => b.position.localeCompare(a.position))

        }
        setStaffDirectory(arr)
    }
    return (
        <>
            <div className='wrapper'>
                <div className='container'>
                    <div className='row w-100 m-0'>
                        {/* <div className='col w-100'> */}
                        <Table hover className='staff-table table-width'>
                            <thead className='staff-table-header'>
                                <tr className='staff-table table-width '>
                                    {/* <th className='staff-table table-width text-start'>Sr #</th> */}
                                    <th className='staff-table table-width  text-start'>
                                        Name
                                        <BiSortAlt2 className='ms-3 fs-5' style={{ cursor: 'pointer' }} onClick={() => handleSort('name')} />
                                    </th>
                                    {/* <th className='staff-table table-width   text-start'>Last Name</th> */}
                                    <th className='staff-table table-width text-start'>Photo</th>
                                    <th className='staff-table table-width text-start'>
                                        Position
                                        <BiSortAlt2 className='ms-3 fs-5' style={{ cursor: 'pointer' }} onClick={() => handleSort('position')} />
                                    </th>
                                    <th className='staff-table table-width w-22 text-start'>Contact Number</th>
                                    {/* <th className='staff-table table-width w-22 text-start'>Department</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {staffDirectory && staffDirectory?.map((item, index) => {
                                    return <tr className='staff-table table-width staff-table-hover' key={index}>
                                        {/* <td className='staff-table table-width w-10 '>{index + 1}</td> */}
                                        <td className='staff-table table-width' >
                                            <a href={`mailto:${item?.email}`} style={{ color: '#7030d6' }} className='border-0 text-decoration-none'>
                                                {item?.fname} {item?.lname}
                                            </a>
                                        </td>
                                        {/* <td className='staff-table table-width'>{item.lname}</td> */}
                                        <td className='staff-table table-width'><img src={`${staff_path}/${item?.photo}`} alt="image" className='' style={{
                                            height: "50px", width: '50px', verticalAlign: 'middle', borderStyle: 'none'
                                        }} /></td>
                                        <td className='staff-table table-width'>{item.position}</td>
                                        <td className='staff-table table-width'>{item.mobile_no}</td>
                                        {/* <td className={`staff-table table-width`}>{item?.department == null ? '-' : item?.department}</td> */}
                                    </tr>
                                })}


                            </tbody>
                        </Table>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaffDirectory