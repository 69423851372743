import React ,{createContext,useState} from 'react';

export const UserValAuthContext = createContext();

const UserAuthProvider = ({children})=>{
    const [loading,setLoading]=useState(false)
    const [disable,setDisable]=useState(true);
    
    return <UserValAuthContext.Provider value={{loading,setLoading,disable,setDisable}}>{children}</UserValAuthContext.Provider>
}
export default UserAuthProvider