import React, { useEffect, useState,useContext } from 'react'
import { useLocation } from 'react-router';
import { EVENTS, FAQ, FORMS, MAP_DIRECTION, PRO_EM_TRAINING, STAFF_DIRECTORY, UNIFORM, VAULT } from '../../services/RouthPath';
import InputButton from '../inputfields/InputButton';
import { useSelector } from 'react-redux';

const Heading = () => {
    const location = useLocation();
    const [heading, setHeading] = useState("");

    const lmsLink = useSelector((s) => s.EmpReducer.lmsLinkObj)

    useEffect(() => {
        switch (location.pathname) {
            case PRO_EM_TRAINING:
                setHeading("PRO EM Training")
                break;
            case STAFF_DIRECTORY:
                setHeading("Staff Directory")
                break;
            case EVENTS:
                setHeading("Upcoming Events")
                break;
            case UNIFORM:
                setHeading("PRO EM Uniform")
                break;
            case VAULT:
                setHeading("Vault")
                break;
            case MAP_DIRECTION:
                setHeading("Map & Directions")
                break;
            case FORMS:
                setHeading("Forms")
                break;
            case FAQ:
                setHeading("Frequently Asked Questions")
                break;
        }
    }, [location])
    return (
        <>
            {/* <div className='wrapper'> */}
            <div className='container-fluid mt-5' >
                <div className='row justify-content-center'>
                   
                   {/* heading - right side button ( if training video text availabel ) */}
                    <div className='col-10 d-flex flex-row align-items-center'>
                        <h2 className='header_heading text-center mt-2 fs-5 mb-3'>{heading}</h2>
                        {lmsLink?.lms_text && location.pathname == PRO_EM_TRAINING && <InputButton type="button" className='btn-blueviolet ms-2 button-lg' name={lmsLink?.lms_text} onClick={() => {
                            window.open(lmsLink?.lms_link, '_blank', 'noopener,noreferrer');
                         }} />}
                    </div>
                </div>
            </div>
            {/* </div> */}

        </>
    )
}

export default Heading