export const EVENTS="/upcoming-event";
export const PRO_EM_TRAINING="/pro-em-training";
export const STAFF_DIRECTORY="/staff-directory";
export const LOGIN="/login";
export const MAP_DIRECTION="/map-direction"
export const VAULT="/vault"
export const FORMS="/forms"
export const FAQ="/faq"
export const UNIFORM="/uniform"
export const EMP_OF_WEEK="/employee-of-week"

