import React from 'react'
import { useLocation } from 'react-router';
import "../../css/Home.css"
import { EVENTS, FORMS, MAP_DIRECTION, PRO_EM_TRAINING, STAFF_DIRECTORY, UNIFORM, VAULT ,FAQ, EMP_OF_WEEK} from '../../services/RouthPath';
import Header from '../layout/Header';
import Content from './Content';
import Events from './Events';
import Forms from './Forms';
import Map_Direction from './Map_Direction';
import ProEmTraining from './ProEmTraining';
import StaffDirectory from './StaffDirectory';
import Uniform from './Uniform';
import Vault from './Vault';
import Faq from './FAQ';
import Footer from '../layout/Footer';
import ImgRibbonCard from '../layout/ImgRibbonCard';
import Heading from './Heading';

const Home = () => {
   const location=useLocation();
    return (
        <>
         <Header />
         {location.pathname == EMP_OF_WEEK  ? <ImgRibbonCard/>:null}
         {location.pathname != EMP_OF_WEEK ? <Heading/>:null}
         {location.pathname == EMP_OF_WEEK ? <Content/>:null}
         {location.pathname == EVENTS ? <Events/>:null}
         {location.pathname == PRO_EM_TRAINING ? <ProEmTraining/>:null}
         {location.pathname == STAFF_DIRECTORY ? <StaffDirectory/>:null}
         {location.pathname == UNIFORM ? <Uniform/>:null}
         {location.pathname == VAULT ? <Vault/>:null}
         {location.pathname == MAP_DIRECTION ? <Map_Direction/>:null}
         {location.pathname == FORMS ? <Forms/>:null}
         {location.pathname == FAQ ? <Faq/>:null}
         <Footer/>
        </>
    )
}

export default Home